<div ngClass="{{dragAreaClass}}">
    <div>{{ instructions }}</div>
    <div>or</div>
    <div class="upload-file">
        <button class="upload-file-btn" href="javascript:void(0)" (click)="file.click()">
            {{ uploadMessage }}
        </button>
    </div>
    <div class="file-name"> {{ fileName || maxFileSizeMessage }}</div>
    <input id="file" type="file" required #file (change)="onFileChange($event)" style="display:none"
        accept="{{ accept }}" [attr.multiple]="multipleFiles ? true : null" />
</div>
