<button class="button-with-dropdown" [matMenuTriggerFor]="menu">
  <mat-icon fontIcon="add" class="icon-plus"></mat-icon>
  {{ label }} <span class="line">|</span>
  <mat-icon fontIcon="expand_more" class="icon-arrow-down"></mat-icon>
</button>
<mat-menu #menu="matMenu" class="menu">
  @for(menuItem of menuItems; track menuItem.link){
    <button mat-menu-item routerLink="{{menuItem.link}}">
        @if(menuItem?.image) {
          <img src="{{ menuItem.image }}" width="24px" height="24px"/>{{ menuItem.label }}
        } 
        @else {
          <div class="icon-container">
            <mat-icon>
              {{ menuItem?.icon }}
            </mat-icon>
          </div>
          {{ menuItem.label }}
        }
    </button>
  }
</mat-menu>
