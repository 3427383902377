import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@beyou/environments/environment';
import { firstValueFrom, Observable } from 'rxjs';
import { FileUploadPurpose } from '../../enums/file-upload-purpose.enum';

type SignedUpload = {
  url: string;
  key: string;
  bucket: string;
};

@Injectable({
  providedIn: 'root'
})
export class S3FileApiService {

  constructor(private http: HttpClient) { }

  public async getSignedUrl(key: string, bucket: string): Promise<string> {
    const apiUrl = `${environment.adminApiBaseUrl}/s3-files/signed-url?key=${key}&bucket=${bucket}`;
    const answer = await firstValueFrom(this.http.get<{ url: string }>(apiUrl));
    return answer.url;
  }

  public async getSignedUploadUrl(extension: string, identifier: string, purpose: FileUploadPurpose = FileUploadPurpose.General): Promise<SignedUpload> {
    const apiUrl = `${environment.adminApiBaseUrl}/s3-files/signed-upload?extension=${extension}&identifier=${identifier}&purpose=${purpose}`;
    const answer = await firstValueFrom(this.http.get<SignedUpload>(apiUrl));
    console.debug('getSignedUploadUrl', answer);
    return {
      url: answer.url,
      key: answer.key,
      bucket: answer.bucket
    }
  }

  public uploadToSignedUrl(url: string, file: File | string): Observable<any> {
    if (file instanceof File) {
      const config = {
        headers: {
          "Content-Type": file instanceof File ? file.type : 'application/octet-stream',
        }
      };
      return this.http.put(url, file, config);
    } else {
      const data = new FormData();
      data.append('file', file);
      return this.http.put(url, data);
    }
  }
}
