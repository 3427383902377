import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMenuItem } from '@beyou/app/core/interfaces/ui/menu-item.interface';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() showBreadcrumbs: boolean = false;
  @Input() showSubtitle: boolean = false;
  @Input() menuItems: IMenuItem[] = [];
  @Input() showButton: boolean = true;
  @Input() buttonLabel: string = 'Page header no button label';
  @Input() buttonEnabled: boolean = true;
  @Output() onButtonClick = new EventEmitter<any>();

  handleClick(event: MouseEvent) {
    event.stopPropagation();
    this.onButtonClick.emit();
  }
}
