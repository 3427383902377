<div>
  <div class="flex">
    <div>
      <h1 [class.h1-with-breadcrumb]="showBreadcrumbs || showSubtitle">{{ title }}</h1> 
      @if(showBreadcrumbs) {
        <app-breadcrumbs></app-breadcrumbs>
      }
      @if(showSubtitle) {
        <div class="subtitle">{{ subtitle }}</div>
      }
    </div>
    
    <!-- Optional button with dropdown -->
    @if(menuItems.length > 0 && showButton) {
      <app-menu-button-with-dropdown [menuItems]="menuItems" [label]="buttonLabel"></app-menu-button-with-dropdown>
    }
    
    <!-- Optional button without dropdown -->
    @if(menuItems.length < 1 && showButton) {
      <button class="button-with-action" [disabled]="!buttonEnabled" (click)="handleClick($event)">
        {{ buttonLabel }}
      </button>
    }
  
  </div>
</div>
