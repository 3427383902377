<div class="player-controls">
  @if(!isPlaying) {
    <button mat-icon-button color="accent" (click)="play()" [disabled]="!playButtonEnabled">
      <mat-icon>play_circle</mat-icon>
    </button>
  }
  @else {
    <button mat-icon-button color="accent" (click)="pause()">
      <mat-icon>stop_circle</mat-icon>
    </button>
  }
  
  <div class="time-info">{{ sessionDuration | durationFormat }} / {{ currentPosition | durationFormat  }}</div>
  <mat-progress-bar
          color="accent"
          [mode]="progressBarMode"
          [value]="currentProgress">
      </mat-progress-bar>
</div>

<h4>Volume control</h4>
<div class="volume-controls">
  <ng-template #volumeSlider let-title="title" let-value="value">
    <div class="slider-container">
      <div class="slider-container-label">{{title}}</div>
      <div class="slider-container-input">
        <mat-slider color="accent"
            max="1"
            min="0"
            step="0.1"
            discrete="true"
            showTickMarks="false">
          <input matSliderThumb  [value]="value" (valueChange)="onVolumeChange($event, title)">
        </mat-slider>
      </div>
    </div>
  </ng-template>
  <ng-container *ngTemplateOutlet="volumeSlider; context: sliderContexts.audio"></ng-container>
  <ng-container *ngTemplateOutlet="volumeSlider; context: sliderContexts.binaural"></ng-container>
  <ng-container *ngTemplateOutlet="volumeSlider; context: sliderContexts.background"></ng-container>
</div>
