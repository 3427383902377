import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-under-contruction',
  templateUrl: './under-contruction.component.html',
  styleUrls: ['./under-contruction.component.scss']
})
export class UnderContructionComponent {
  @Input() title: string = 'Some component';
}
