import {
  Auth,
  GoogleAuthProvider,
  User,
  UserCredential,
  createUserWithEmailAndPassword,
  idToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  user,
} from '@angular/fire/auth';

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAuthService, LoginRequestDto } from '@beyou/app/core/interfaces/services/auth-service.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements IAuthService {

  user$ = user(this.auth);
  idToken$ = idToken(this.auth);
  token$ = new BehaviorSubject<string>('');

  constructor(private auth: Auth) {
    this.user$.subscribe((user) => {
      if (user) {
        user?.getIdToken().then((token) => this.token$.next(token));
      } else {
        this.token$.next('');
      }
    });


    this.idToken$.subscribe((token: string | null) => {
      if (token) {
        console.debug('refresh token');
        this.token$.next(token);
      }
    });
  }

  login({ email, password }: LoginRequestDto): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  loginWithGoogle(): Promise<UserCredential> {
    return signInWithPopup(this.auth, new GoogleAuthProvider());
  }

  register({ email, password }: LoginRequestDto): Promise<UserCredential> {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  logout(): Promise<void> {
    return signOut(this.auth);
  }
}
