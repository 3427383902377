import { Component, OnDestroy } from '@angular/core';
import { CustomerService } from '@beyou/app/core/store/features/customer.service';
import { UserService } from '@beyou/app/core/store/features/user.service';
import { Subscription, combineLatest, map } from 'rxjs';

interface IProfile {
  userName: string;
  customerName: string;
}

@Component({
  selector: 'app-profile-selector',
  templateUrl: './profile-selector.component.html',
  styleUrls: ['./profile-selector.component.scss']
})
export class ProfileSelectorComponent implements OnDestroy {
  profile: IProfile | null = null;
  subscription:Subscription;

  constructor( 
    private customerService: CustomerService,
    private userService: UserService
  ) { 

    this.subscription = combineLatest([this.userService.currentUser$, this.customerService.currentCustomer$]).pipe(
      map(([user, customer]) => ({ 
        userName: `${user?.firstName} ${user?.lastName}`,
        customerName: customer?.name
      } as IProfile))
    ).subscribe(profile => {
      if(profile && profile.userName && profile.customerName){
        this.profile = profile;
      } else {
        this.profile = null;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
