<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="menu-tree">
  <!-- This is the tree node template for leaf nodes -->
  <!-- There is inline padding applied to this node using styles.
    This padding value depends on the mat-icon-button width. -->
  <mat-tree-node routerLink="{{node.link}}" *matTreeNodeDef="let node" matTreeNodeToggle>
    <div class="mat-tree-node" [class.active]="!!node.isCurrent">
        @if(!!node.icon) {
          <svg-icon src="{{node.icon}}" class="mat-icon mat-tree-node-icon"></svg-icon>
        }
        {{node.name}}
    </div>
  </mat-tree-node>
  
  <!-- This is the tree node template for expandable nodes -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <div class="mat-tree-node" [class.active]="!!node.isCurrent" matTreeNodeToggle>
        @if(!!node.icon) {
          <svg-icon src="{{node.icon}}" class="mat-icon mat-tree-node-icon"></svg-icon>
        }
        {{node.name}}
      </div>
      <!-- There is inline padding applied to this div using styles.
          This padding value depends on the mat-icon-button width.  -->
      <div [class.menu-tree-invisible]="!treeControl.isExpanded(node)"
          role="group" [ngClass]="!!node.isCurrent ? 'active-border' : 'inactive-border'">
        <div class="border-fix-top">&nbsp;</div>
        <ng-container matTreeNodeOutlet></ng-container>
        <div class="border-fix-bottom">&nbsp;</div>
    </div>
  </mat-nested-tree-node>
</mat-tree>

<mat-tree [dataSource]="bottomDataSource" [treeControl]="bottomTreeControl" class="menu-tree">
  <mat-tree-node routerLink="{{node.link}}" *matTreeNodeDef="let node" matTreeNodeToggle>
    <div class="mat-tree-node" [class.active]="!!node.isCurrent">
        @if(!!node.icon) {
          <svg-icon src="{{node.icon}}" class="mat-icon mat-tree-node-icon"></svg-icon>
        }
        {{node.name}}
    </div>
  </mat-tree-node>
</mat-tree>