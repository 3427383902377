import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-with-dropdown',
  templateUrl: './button-with-dropdown.component.html',
  styleUrls: ['./button-with-dropdown.component.scss']
})
export class ButtonWithDropdownComponent {

  @Input() label: string = '';
  @Input() theme: 'normal' | 'light' = 'normal';

}
