import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { Observable, map } from 'rxjs';

interface IBreadcrumb {
  label: string;
  link?: string;
}

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {
  breadcrumbs: IBreadcrumb[] = [];

  constructor(private route:ActivatedRoute) { 
    const segments = route.snapshot.url;
    const modulePath = route.snapshot.parent?.url[0].path || '';
    this.breadcrumbs = this.createBreadcrumbs(segments, modulePath);
  }

  private createBreadcrumbs(segments:UrlSegment[], modulePath:string): IBreadcrumb[] {
    // TODO: This is a temporary solution. It should be refactored to be more generic.
    // For example, by using the data property of the route to set the breadcrumb label.
    //console.log(segments, modulePath);
    let breadcrumbs: IBreadcrumb[] = [];
    if(modulePath == 'clients') {
      breadcrumbs = [
        { label: 'Clients', link: '/clients' }
      ];
      
      if(segments.length > 2) {
        breadcrumbs.push({ label: 'Client profile', link: `/clients/${segments[0].path}`})
        if(segments[1].path == 'session'){
          if(segments[2].path == 'create'){
            breadcrumbs.push({ label: 'New client session' });
          } else {
            breadcrumbs.push({ label: 'Client session details' });
          }
        } else if(segments[1].path == 'program') {
          breadcrumbs.push({ label: 'Client program' });
        }
      } else if(segments.length === 2 && segments[1].path == 'edit') {
        breadcrumbs.push({ label: 'Client profile', link: `/clients/${segments[0].path}`});
        breadcrumbs.push({ label: 'Edit' });
      } else if(segments.length === 1) {
        breadcrumbs.push({ label: 'Client profile' });
      }

    } else if(segments[0].path == 'building-blocks') {
      const blockType = segments[1].path;
      breadcrumbs = [
        { label: 'Building block templates', link: '/content/building-blocks' },
      ];
      
      let label = '';
      if(blockType == 'manage') {
        label = 'Manage blocks';
      } else {
        const action  = segments && segments.length === 4? segments[3].path : 'create';
        label = action == 'edit'? `Edit ${blockType} building block template` : `New ${blockType} building block template`
      }
      
      breadcrumbs.push({ label: label });

    } else if(segments[0].path == 'audio-sessions') {
      const action  = segments && segments.length === 3? segments[2].path : 'create';
      const label = action == 'edit'? `Edit audio session template` : `New audio session template`;
      breadcrumbs = [
        { label: 'Audio session templates', link: '/content/audio-sessions' },
        { label:  label }
      ];

    }
    
    return breadcrumbs;
  }
}
