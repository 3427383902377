import { LocaleDto } from "../dto/locale.dto";
import { Locale } from "../models/locale.model";

export class LocaleMapper {
  public static toModel(dto:LocaleDto): Locale {
    return {
      id: dto.id,
      code: dto.code,
      name: dto.display_name,
      isDefault: dto.is_default
    }
  }
}