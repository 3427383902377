import { CustomerDto } from "../../dto/customers/customer.dto";
import { Customer } from "../../models/customers/customer.model";

export class CustomerMapper {
  public static toModel(dto: CustomerDto): Customer {
    return {
      id: dto.id,
      name: dto.name,
      isDefault: dto.is_default
    }
  }
}