import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

interface MenuNode {
  name: string;
  icon?: string;
  link?: string;
  children?: MenuNode[];
  isCurrent?: boolean ;
}

const svg_icon_base_path = '/assets/svg/menu-icons';

const TREE_DATA: MenuNode[] = [
  {
    name: 'Overview',
    link: '/dashboard',
    icon: `${svg_icon_base_path}/overview.svg`,
  },
  {
    name: 'Configuration',
    icon: `${svg_icon_base_path}/configuration.svg`,
    children: [
      {
        name: 'Tags',
        link: '/content/config/tags',

      },
      {
        name: 'Placeholders',
        link: '/content/config/custom-placeholders',
      },
      {
        name: 'SMART² methods',
        link: '/content/config/smart-programs',
      },
    ]
  },
  {
    name: 'Templates',
    icon: `${svg_icon_base_path}/content.svg`,
    children: [
      {
        name: 'Building blocks',
        link: '/content/building-blocks',

      },
      {
        name: 'Audio sessions',
        link: '/content/audio-sessions',
      },
      {
        name: 'Series',
        link: '/content/series',
      },
      {
        name: 'Programs',
        link: '/content/programs',
      },
    ],
  },
  {
    name: 'Clients',
    link: '/clients',
    icon: `${svg_icon_base_path}/clients.svg`,
  },
  /*
  {
    name: 'Finance',
    icon: `${svg_icon_base_path}/finance.svg`,
    children: [
      {
        name: 'Direct revenue',
        link: '/finance/direct-revenue',
      },
      {
        name: 'Commissions',
        link: '/finance/commissions',
      },
    ],
  },
  {
    name: 'Shop',
    link: '/shop',
    icon: `${svg_icon_base_path}/shop.svg`,
  }
  */
];

const BOTTOM_TREE_DATA: MenuNode[] = [
  {
    name: 'Settings',
    link: '/user-account/settings',
    icon: `${svg_icon_base_path}/settings.svg`,
  },
  {
    name: 'Logout',
    link: '/auth/logout',
    icon: `${svg_icon_base_path}/logout.svg`,
  },
];

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, OnDestroy{
  treeControl = new NestedTreeControl<MenuNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<MenuNode>();
  bottomTreeControl = new NestedTreeControl<MenuNode>(node => node.children);
  bottomDataSource = new MatTreeNestedDataSource<MenuNode>();
  private subscription = new Subscription();
  private isLaunch = true;

  constructor(private router: Router) {
    this.dataSource.data = TREE_DATA;
    this.bottomDataSource.data = BOTTOM_TREE_DATA;
  }

  ngOnInit() {

    this.subscription.add(this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const newRoute = event.urlAfterRedirects;
        this.updateMainTree(newRoute);
        this.updateBottomTree(newRoute);
        this.isLaunch = false;
      }
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private updateNode(node: MenuNode, route: string) {
    if (node.link && route.startsWith(node.link)) {
      node.isCurrent = true;
    } else {
      node.isCurrent = false;
    }
    if (node.children) {
      node.children.forEach((child) => {
        this.updateNode(child, route);
      });
    }
  }

  private updateTree(tree: MenuNode[], route: string) {
    tree.forEach((node) => {
      this.updateNode(node, route);
      // If the current node is a child of another node, set the parent node to current
      if(node.children?.find((child) => child.isCurrent)){
        node.isCurrent = true;
        if(this.isLaunch){
          this.treeControl.expand(node);
        }
      }
    });
  }

  private updateMainTree(route:string){
    const tree = this.dataSource.data;
    this.updateTree(tree, route);
    this.dataSource.data = tree;
  }

  private updateBottomTree(route:string){
    const tree = this.bottomDataSource.data;
    this.updateTree(tree, route);
    this.bottomDataSource.data = tree;
  }

  hasChild = (_: number, node: MenuNode) => !!node.children && node.children.length > 0;
}
