import { AudioFile } from "../media/audio-file.model";

export enum BlockMainType {
  Script = "script",
  Audio = "audio",
}

export enum BlockSubType {
  Standard = 'standard',
  BinauralBeats = 'binaural_beats',
  BackgroundSound = 'background_sound',
}

export type BlocksFilter = {
  domain: string;
  purpose: string;
  blockType: string;
  category: string;
  duration: string;
  language: string;
  tag: string;
}

export type BuildingBlockTemplateTranslation = {
  localeId: number;
  title: string;
  script: string;
  llmInstructions: string;
}

export type BuildingBlockTemplateAudioTranslation = AudioFile & {
  localeId: number;
}

export type BuildingBlockTemplateBase = {
  title: string;
  mainType: BlockMainType;
  /** Type: describes what kind of block it is (main, binaural beats or background sound) */
  type: BlockSubType;
  /** 
   * Category describes where in the session it is used (Intro, outro etc). 
   * Only applies to blocks of type main. 
   * */
  categoryCode: string;
  script: string;
  file?: AudioFile;
  creator: string;
  /** LLM instructions */
  llmInstructions?: string;
  tags?: string[];
  /** The therapeutic domain this building block belongs to. Only applies to blocks of type main. */
  therapeuticDomainCode?: string;
  /** The purpose of . Only applies to blocks of type main. */
  purposeCode?: string;
  /** Frequency variarion in the beats. Only applies to blocks of type binaural. */
  binauralFrequencyVariationCode?: string;
  /** Range in the beats. Only applies to blocks of type binaural. */
  binauralRangeCode?: string;
  placeholderGroupId?: number;
}

export type BuildingBlockTemplate = BuildingBlockTemplateBase & {
  id: number;
  originalLocaleId: number;
  updatedAt?: Date | null;
  createdAt?: Date;
}