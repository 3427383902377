import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISelectorMenuItem } from '@beyou/app/core/interfaces/ui/selector-menu-item.interface';

@Component({
  selector: 'app-selector-menu-with-icons',
  templateUrl: './selector-menu-with-icons.component.html',
  styleUrls: ['./selector-menu-with-icons.component.scss']
})
export class SelectorMenuWithIconsComponent {
  @Input() items: ISelectorMenuItem [] = [];
  @Output() select = new EventEmitter<string>();
  @Output() edit = new EventEmitter<string>();

  onSelected(identifier: string) {
    this.select.emit(identifier);
  }

  onEdit(identifier: string) {
    this.edit.emit(identifier);
  }
}
