import { Injectable } from '@angular/core';
import { catchError, EMPTY, expand, filter, finalize, Observable, of, retry, scan, takeLast, throwError } from 'rxjs';
import { PagedResponse } from '../dto/paged-response';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseApiService {

  constructor(protected http: HttpClient) { }

  protected getAllPagedData<T>(url: string): Observable<T[]> {
    const initialPage = 1;
    const maxRetries = 3;

    return this.fetchPage<T>(initialPage, url).pipe(
      expand((pagedResponse) => {
        if (pagedResponse?.has_next_page) {
          return this.fetchPage<T>(pagedResponse.page_index + 1, url).pipe(
            // Retry logic for each page fetch
            retry(maxRetries)
          );
        } else {
          return EMPTY; // Use EMPTY instead of of(null) to complete the stream
        }
      }),
      // No need to filter out null values as we're using EMPTY
      // Accumulate all items into a single array
      scan((allItems: T[], pagedResponse) => {
        return allItems.concat(pagedResponse?.data ?? []);
      }, []),
      // Take the last emitted value (complete array) and complete the stream
      takeLast(1),
      // Catch any errors that might occur during the process
      catchError((error) => {
        console.error('Error fetching paged data:', error);
        return throwError(() => new Error('Failed to fetch all paged data'));
      }),
      finalize(() => console.debug('All pages fetched'))
    );
  }

  private fetchPage<T>(page: number, url: string): Observable<PagedResponse<T>> {
    const params = { page_index: page.toString() };
    return this.http.get<PagedResponse<T>>(url, { params });
  }
}
