<div class="profile-selector">
  <div class="profile-selector-image" [style.backgroundImage]="'url(/assets/images/example_profile.png)'">
    <!-- <img width="40px" height="40px" src="/assets/images/example_profile.png" alt="therapist" /> -->
  </div>
  
  @if (profile) {
    <div>
      <div class="mat-subtitle-1">{{ profile.userName }}</div>
      <div class="mat-subtitle-2">&#64;{{ profile.customerName }}</div>
    </div>
  }
  
  <div class="profile-selector-button">
    <button mat-icon-button>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </div>
</div>
