import { Component, Input } from '@angular/core';
import { IMenuItem } from '@beyou/app/core/interfaces/ui/menu-item.interface';

@Component({
  selector: 'app-menu-button-with-dropdown',
  templateUrl: './menu-button-with-dropdown.component.html',
  styleUrls: ['./menu-button-with-dropdown.component.scss']
})
export class MenuButtonWithDropdownComponent {
  
  @Input() label: string = 'No label';
  @Input() menuItems: IMenuItem[] = [];
}
