import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-selector-menu-with-icons-list-item',
  templateUrl: './selector-menu-with-icons-list-item.component.html',
  styleUrls: ['./selector-menu-with-icons-list-item.component.scss']
})
export class SelectorMenuWithIconsListItemComponent {
  @Input() title = 'title here';
  @Input() subtitle = 'subtitle here';
  @Input() icon = '';
  @Input() imageUrl = '';
  @Input() isSelected = false;
  @Input() backgroundColor = 'white';
  @Input() identifier = '';
  @Input() isEditable = false;

  @Output() select = new EventEmitter<string>();
  @Output() edit = new EventEmitter<string>();

  onSelected() {
    this.select.emit(this.identifier);
  }

  onEdit() {
    this.edit.emit(this.identifier);
  }
}
