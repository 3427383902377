import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { getFileExtension } from '@beyou/app/core/utils/filehelper';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
    dragAreaClass = 'dragarea';
    fileName: string = '';
    translations: any;
    maxFileSizeMessage!: string;
    @Input() fileExt = 'mp3';
    @Input() accept = '.mp3';
    @Input() maxSize = 150;  // Maximum supported size of file in MB, default is 150
    @Input() multipleFiles = false;
    @Input() uploadMessage = "Upload your file";
    @Input() instructions = "Drag and drop a file from your file system";
    // default message
    @Input() fileTooLargeMessage: string = 'The file size exceeds 150MB';
    maxFileCount = 100;
    maxSizeBytes: number = +(this.maxSize) * 1024 * 1024;
    @Output() changeFile = new EventEmitter();

    constructor() { }

    ngOnInit() {

        if (typeof this.maxSize === 'undefined') {
            this.maxSizeBytes = 150 * 1024 * 1024;
            this.maxSize = 150;
        } else {
            this.maxSizeBytes = +(this.maxSize) * 1024 * 1024;
        }

        if (!this.fileTooLargeMessage) {
            this.fileTooLargeMessage = `The file size exceeds ${this.maxSize}MB`;
        }

        this.maxFileSizeMessage = `Max file size is ${this.maxSize}MB (${this.accept} only)`;
    }

    handleFileChange(files: Array<File>) {

        if (files.length !== 1 && !this.multipleFiles) {
            return;
        }

        if (!this.isValidFileExtension(files)) {
            console.log('invalid file extension');
            return;
        }

        if (this.multipleFiles && files.length > this.maxFileCount) {
            return;
        }

        for (let file of files) {
            if (file.size > this.maxSizeBytes) {
                return;
            }
        }

        if (!this.multipleFiles) {
            this.fileName = files[0].name;
        }

        this.changeFile.emit(files[0]);
    }

    onFileChange(event: any) {
        const files = <Array<File>>event.target.files;
        this.handleFileChange(files);
    }

    @HostListener('dragover', ['$event']) onDragOver(event: Event) {
        this.dragAreaClass = 'droparea';
        event.preventDefault();
    }

    @HostListener('dragenter', ['$event']) onDragEnter(event: Event) {
        this.dragAreaClass = 'droparea';
        event.preventDefault();
    }

    @HostListener('dragend', ['$event']) onDragEnd(event: Event) {
        this.dragAreaClass = 'droparea';
        event.preventDefault();
    }

    @HostListener('dragleave', ['$event']) onDragLeave(event: Event) {
        this.dragAreaClass = 'droparea';
        event.preventDefault();
    }
    @HostListener('drop', ['$event']) onDrop(event: any) {
        this.dragAreaClass = 'dragarea';
        event.preventDefault();
        event.stopPropagation();
        const files = <Array<File>>event.dataTransfer.files;
        this.handleFileChange(files);
    }

    private isValidFileExtension(files: any) {

        let exist = true;
        const extensions = (this.fileExt.split(','))
            .map(function (x) { return x.toLocaleLowerCase().trim(); });
        for (let i = 0; i < files.length; i++) {

            const ext = getFileExtension(files[i].name) || files[i].name;
            console.log(extensions);
            const exists = extensions.indexOf(ext);
            if (exists === -1) {
                exist = false;
            }
        }

        return exist;
    }
}
