import { S3File } from "../models/media/s3-file.model";

export const getFileExtension = (filename: string): string => {
  //const ext = filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
  const ext = filename.split('.').pop()?.toLowerCase();
  console.log('File extension', ext);
  return ext || '';
};

export const getS3FileUrl = (file: S3File): string => {
  return `https://s3.${file.region}.amazonaws.com/${file.bucket}/${file.key}`;
}