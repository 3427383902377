<div class="container" [class.selected]="!!isSelected" (click)="onSelected()">
  <div class="category-icon" [style.background-color]="backgroundColor">
    @if(!!icon) {
      <svg-icon [src]="icon"></svg-icon>
    }
    @else if(!!imageUrl) {
      <img class="category-icon-img" [src]="imageUrl" />
    }
    @else {
      <mat-icon [style.color]="backgroundColor.replace('0.1', '1.0')">category</mat-icon>
    }
    
  </div>
  <div class="category-info">
    <div class="category-info-title">{{ title }}</div>
    <div class="category-info-subtitle">{{ subtitle }} </div>
  </div>
  
  @if(!!isEditable) {
    <div class="category-actions">
      <mat-icon (click)="onEdit()">edit_note</mat-icon>
    </div>
  }
</div>