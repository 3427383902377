import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { BlockSubType } from '@beyou/app/core/models/building-blocks/building-block-template.model';
import { AuthService } from '@beyou/app/core/services/auth.service';
import { S3FileApiService } from '@beyou/app/core/services/api/s3file-api.service';
import {Howl, Howler} from 'howler';
import { Session, SessionDetails } from '@beyou/app/core/models/sessions/session.model';
import { AudioFile } from '@beyou/app/core/models/media/audio-file.model';

interface ITrackInfo {
  track: AudioFile | null;
  volume: number;
  url: string;
  soundId?: number;
  soundIndex?: number;
}

@Component({
  selector: 'app-session-player',
  templateUrl: './session-player.component.html',
  styleUrls: ['./session-player.component.scss']
})
export class SessionPlayerComponent implements OnChanges, OnDestroy {
  @Input() session: SessionDetails| null = null;
  @Input() files: Map<BlockSubType, String> = new Map(); //Normally only used for anonymous/unauthenticated access

  @Output() loaded = new EventEmitter<ITrackInfo[]>();
  
  mainTrack:ITrackInfo|null = null;
  binauralBeatsTrack:ITrackInfo|null = null;
  backgroundSoundTrack:ITrackInfo|null = null;

  sounds: Howl[] = [];
  sessionDuration = 0; //in seconds
  currentPosition = 0; //in seconds
  currentProgress = 0; //in percentage
  maxTrackDuration = 0; //in seconds
  isPlaying = false;
  intervalId: any;
  progressBarMode:ProgressBarMode = 'determinate';

  defaultMainVolume = 0.8;
  defaultBinauralVolume = 0.5;
  defaultBackgroundVolume = 0.3;

  sliderContexts = {
    audio: {
      title: 'Main audio',
      value: this.defaultMainVolume,
    },
    binaural: {
      title: 'Binaural beats',
      value: this.defaultBinauralVolume,
    },
    background: {
      title: 'Background sound',
      value: this.defaultBackgroundVolume,
    }
  }

  constructor(
    private s3FileApiService: S3FileApiService,
    private auth: AuthService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['session'] && changes['session'].currentValue !== changes['session'].previousValue){
      this.load();
    }
  }

  get playButtonEnabled(): boolean  {
    return !this.isPlaying && this.session !== null;
  }

  async load() {
    if(!this.session) return;
    
    this.mainTrack = { track: this.session.mainAudio || null, url: '', volume: this.defaultMainVolume};
    this.binauralBeatsTrack = { track:  this.session.binauralAudio || null, url: '', volume: this.defaultBinauralVolume};
    this.backgroundSoundTrack = { track: this.session.backgroundAudio || null, url: '', volume: this.defaultBackgroundVolume };
    
    const tracks = [this.mainTrack, this.binauralBeatsTrack, this.backgroundSoundTrack].filter(t => t?.track !== null);
    console.log(this.mainTrack, this.binauralBeatsTrack, this.backgroundSoundTrack);
    // Get the maximum duration of the tracks
    const sd = Math.round(Math.max(...tracks.map(t => t!.track!.duration)));
    if(isNaN(sd) || !isFinite(sd)){
      console.debug('Invalid session duration');
      this.sessionDuration = 0;
    } else {
      this.sessionDuration = sd;
    }
    console.debug('Session duration: ', this.sessionDuration);
  }


  async play(){
    this.progressBarMode = 'buffer';
    this.isPlaying = true;

    const sourceTracks = [this.mainTrack, this.binauralBeatsTrack, this.backgroundSoundTrack].filter(t => t?.track !== null)

    if(this.sounds.length < 1){
      // Checking if we have the files already
      if(this.files.get(BlockSubType.Standard)) this.mainTrack!.url = this.files.get(BlockSubType.Standard)! as string; '';
      if(this.files.get(BlockSubType.BinauralBeats)) this.binauralBeatsTrack!.url = this.files.get(BlockSubType.BinauralBeats)! as string;
      if(this.files.get(BlockSubType.BackgroundSound)) this.backgroundSoundTrack!.url = this.files.get(BlockSubType.BackgroundSound)! as string;
      
      this.maxTrackDuration = Math.max(...sourceTracks.map(t => t!.track!.duration));
      if(!this.files.size){
        for(const t of sourceTracks){
          const url = await this.s3FileApiService.getSignedUrl(t!.track!.key, t!.track!.bucket);
          t!.url = url;
        }
      }
      
      if(sourceTracks){
        console.log(sourceTracks);
        this.loaded.emit(sourceTracks as ITrackInfo[]);
      }
      
      for(let i = 0; i < sourceTracks.length; i++){
        const t = sourceTracks[i];
        console.log(t!.track!.key, t!.url);
        const howl = new Howl({
          src: [t!.url],
          html5: true,
          onend: (soundId) => {
            console.log('Audio finished: ', soundId);
            const t = sourceTracks.find(s => s!.soundId === soundId);
            if(t && t.track?.duration == this.maxTrackDuration){
              console.log('All tracks finished');
              this.currentProgress = 100;
              this.isPlaying = false;
              this.currentPosition = 0;
              clearInterval(this.intervalId);
            }
          },
          onplay: () => {
            console.log("onplay");
            this.progressBarMode = 'determinate';
            this.intervalId = setInterval(() => {
              if(t && t.track?.duration == this.maxTrackDuration){
                const c = this.sounds[i]?.seek() || 0;
                this.currentPosition = Math.round(c);
                this.currentProgress = Math.round((c / this.sessionDuration) * 100);
              }
            }, 300);
          },
          onpause: () => {
            console.log("onpause");
            clearInterval(this.intervalId);
          }
        });

        this.sounds.push(howl);
      }

      // Play returns a unique Sound ID that can be passed
      // into any method on Howl to control that specific sound.
      for(let i = 0; i < sourceTracks.length; i++){
        const s = sourceTracks[i];
        s!.soundId = this.sounds[i].play();
        s!.soundIndex = i;
        console.log(`Playing ${s!.track!.key} with sound id ${s!.soundId} and volume ${s!.volume}`);
        this.sounds[i].volume(s!.volume , s!.soundId);
      }
    
    } else {
      for(let i = 0; i < sourceTracks.length; i++){
        const s = sourceTracks[i];
        const duration = this.sounds[i].duration() || 0;
        console.log(duration, this.currentPosition);
        if(duration > this.currentPosition){
          s!.soundId = this.sounds[i].play();
        } else {
          console.log('Sound already finished');
        }
      }
    }
  }

  async pause(){
    this.isPlaying = false;
    for(let i = 0; i < this.sounds.length; i++){
      const s = this.sounds[i];
      s.pause();
    }
  }

  onVolumeChange(volume: number, title:string){
    if(this.sounds.length < 1) return;
    if(title === this.sliderContexts.audio.title && this.mainTrack?.soundId){
      this.sounds[this.mainTrack.soundIndex!].volume(volume, this.mainTrack.soundId);
    } else if(title === this.sliderContexts.binaural.title && this.binauralBeatsTrack?.soundId){
      this.sounds[this.binauralBeatsTrack.soundIndex!].volume(volume, this.binauralBeatsTrack.soundId);
    } else if(title === this.sliderContexts.background.title && this.backgroundSoundTrack?.soundId){
      this.sounds[this.backgroundSoundTrack.soundIndex!].volume(volume, this.backgroundSoundTrack.soundId);
    }
  }

  ngOnDestroy() {
    for(const s of this.sounds){
      s.unload();
    }
    clearInterval(this.intervalId);
  }
}
