export const environment = {
  production: false,
  firebase : {
    apiKey: "AIzaSyAQy_KsHuw9bnh9z1iZElu32HMlcNl2k5Y",
    authDomain: "bu-dev-9e908.firebaseapp.com",
    projectId: "bu-dev-9e908",
    storageBucket: "bu-dev-9e908.appspot.com",
    messagingSenderId: "201065338630",
    appId: "1:201065338630:web:ff535e09cefeb42adf3087"
  },
  //adminApiBaseUrl : "http://0.0.0.0:8080/admin-api/v1.0",
  adminApiBaseUrl : "https://dev-api.subrise.ai/admin-api/v1.0",
  s3: {
    region: "eu-central-1",
    imagesBucket: "images.dev.beyou.com",
  }
}