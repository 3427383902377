<div class="panel-categories">
  @for(item of items; track item.identifier){
    <div class="panel-categories-list">
        <app-selector-menu-with-icons-list-item 
          [title]="item.title" 
          [subtitle]="item.subtitle" 
          [icon]="item.icon" 
          [imageUrl]="item.imageUrl || ''"
          [isSelected]=item.isSelected
          [backgroundColor]="item.backgroundColor"
          [identifier]="item.identifier"
          (select)="onSelected(item.identifier)"
          (edit)="onEdit(item.identifier)"
          [isEditable]=!!item.isEditable
          >
        </app-selector-menu-with-icons-list-item>
    </div>
  }
</div>
