import { Injectable } from "@angular/core";
import { StoreService } from "..";
import { catchError, from, map, Observable, of, Subscription, tap } from "rxjs";
import { Customer } from "../../models/customers/customer.model";
import { CustomerApiService } from "../../services/api/customer-api.service";

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  
  constructor(
    private store: StoreService, 
    private customerApiService: CustomerApiService
  ) {}

  get currentCustomer$(): Observable<Customer|null> {
    return this.store.state$.pipe(map(state => {
      const v = Object.values(state.customers)
      return v.length ? v[0] : null;
    }));
  }

  load(): Observable<Customer>{
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.customerApiService.get()).pipe(
      tap((customer) => {
        const state = { ...this.store.state };
        state.customers[customer.id] = customer;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading building customers:', error);
        throw error;
      })
    );
  }

  clear(): void {
    const state = { ...this.store.state };
    state.customers = {};
    this.store.setState(state);
  }
}