import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@beyou/environments/environment';
import { firstValueFrom, map } from 'rxjs';
import { Customer } from '../../models/customers/customer.model';
import { CustomerDto } from '../../dto/customers/customer.dto';
import { CustomerMapper } from '../../mappers/customers/customer.mapper';

@Injectable({
  providedIn: 'root'
})
export class CustomerApiService extends BaseApiService {

  constructor(http: HttpClient) {
    super(http);
  }

  public async get(id?: number): Promise<Customer> {
    const customerId = id?.toString() || 'me';
    const apiUrl = `${environment.adminApiBaseUrl}/customers/${customerId}`;
    return await firstValueFrom(this.http.get<CustomerDto>(apiUrl).pipe(
      map((response) => {
        console.debug('Customer retrieved: ', response.id);
        return CustomerMapper.toModel(response);
      })
    ));
  }
}
