import { Injectable } from "@angular/core";
import { Session, SessionBase, SessionDetails } from "../models/sessions/session.model";
import { Voice } from "../models/voices/voice.model";
import { BehaviorSubject, catchError, tap } from "rxjs";
import { Customer } from "../models/customers/customer.model";
import { SubriseUser } from "../models/users/subrise-user.model";
import { TherapeuticDomain } from "../models/therapeutic-domain.model";
import { Locale } from "../models/locale.model";
import { SessionCategory } from "../models/sessions/session-category.model";
import { BuildingBlockPurpose } from "../models/building-blocks/building-block-purpose.model";
import { BuildingBlockSubType } from "../models/building-blocks/building-block-sub-type.model";
import { BuildingBlockBinauralBeatsFrequencyVariation } from "../models/building-blocks/building-block-binaural-beats-frequency-variation.model";
import { BuildingBlockBinauralBeatsRange } from "../models/building-blocks/building-block-binaural-beats-range.model";
import { BuildingBlockCategory } from "../models/building-blocks/building-block-category.model";
import { PlaceholderGroup } from "../models/placeholders/placeholder-group.model";
import { Placeholder } from "../models/placeholders/placeholder.model";
import { BuildingBlockTemplate } from "../models/building-blocks/building-block-template.model";
import { BuildingBlockTag } from "../models/building-blocks/building-block-tag.model";
import { SessionTemplate } from "../models/sessions/session-template.model";
import { SmartSurvey, SmartSurveyDetails } from "../models/smart-programs/smart-survey.model";
import { SmartUserProgram, SmartUserProgramDetails } from "../models/smart-programs/smart-user-program.model";
import { SeriesTemplate, SeriesTemplateDetails } from "../models/series/series-template.model";
import { TimeSlot } from "../models/time-slot.model";
import { ProgramTemplateIntensityLevel } from "../models/programs/program-template-intensity-level.model";
import { ProgramTemplateSessionFrequency } from "../models/programs/program-template-session-frequency.model";
import { ProgramTemplate, ProgramTemplateDetails } from "../models/programs/program-template.model";

interface AppState {
  voices: { [id: number]: Voice };
  customers: { [id: number]: Customer };
  users: { [id: string]: SubriseUser };
  currentUser: SubriseUser | null;
  therapeuticDomains: { [id: number]: TherapeuticDomain };
  locales : { [id: number]: Locale };
  sessionCategories: { [id: number]: SessionCategory };
  buildingBlockCategories: { [id: number]: BuildingBlockCategory };
  buildingBlockPurposes: { [id: number]: BuildingBlockPurpose };
  buildingBlockSubTypes: { [id: number]: BuildingBlockSubType };
  buildingBlockBinauralBeatsFrequencyVariations: { [id: number]: BuildingBlockBinauralBeatsFrequencyVariation };
  buildingBlockBinauralBeatsRanges: { [id: number]: BuildingBlockBinauralBeatsRange };
  placeholderGroups: { [id: number]: PlaceholderGroup };
  placeholders: { [id: number]: Placeholder };
  buildingBlockTemplates: { [id: number]: BuildingBlockTemplate };
  buildingBlockTags: { [id: number]: BuildingBlockTag };
  sessionTemplates: { [id: number]: SessionTemplate };
  smartSurveys: { [id: number]: SmartSurvey | SmartSurveyDetails };
  smartUserPrograms: { [id: number]: SmartUserProgram | SmartUserProgramDetails };
  sessions: { [id: number]: Session | SessionDetails };
  seriesTemplates: { [id: number]: SeriesTemplate | SeriesTemplateDetails };
  timeSlots: { [id: number]: TimeSlot };
  programTemplateIntensityLevels: { [id: number]: ProgramTemplateIntensityLevel };
  programTemplateSessionFrequencies: { [id: number]: ProgramTemplateSessionFrequency };
  programTemplates: { [id: number]: ProgramTemplate | ProgramTemplateDetails };
  loading: boolean;
  error: any;
}

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  
  private initialState: AppState = {
    voices: {},
    customers: {},
    users: {},
    currentUser: null,
    therapeuticDomains: {},
    locales : {},
    sessionCategories: {},
    buildingBlockCategories: {},
    buildingBlockPurposes: {},
    buildingBlockSubTypes: {},
    buildingBlockBinauralBeatsFrequencyVariations: {},
    buildingBlockBinauralBeatsRanges: {}, 
    placeholderGroups: {},
    placeholders: {},
    buildingBlockTemplates: {},
    buildingBlockTags: {},
    sessionTemplates: {},
    smartSurveys: {},
    smartUserPrograms: {},
    sessions: {},
    seriesTemplates: {},
    timeSlots: {},
    programTemplateIntensityLevels: {},
    programTemplateSessionFrequencies: {},
    programTemplates: {},
    loading: false,
    error: null
  };

  private stateSubject = new BehaviorSubject<AppState>(this.initialState);
  state$ = this.stateSubject.asObservable();

  get state(): AppState {
    const t = this.stateSubject.getValue();
    console.debug('state', t);
    return t;
  }

  setState(newState: AppState): void {
    this.stateSubject.next(Object.freeze(newState));
  }
}